import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { Box, Button } from '@mui/material';
import { RenderDocumentIcon } from 'components/RenderDocumentIcon';
import { IDocument } from 'types';
import { SystemStyleObject } from '@mui/system';

interface IBoardMemberViewTopicCardDocumentButton {
  document: IDocument;
  documentIndex: number;
  setCurrentDocumentIndex: Dispatch<SetStateAction<number>>;
  sxContainer?: SystemStyleObject;
}

export const BoardMemberViewTopicCardDocumentButton: React.FC<IBoardMemberViewTopicCardDocumentButton> = (
  props
) => {
  const { document, documentIndex, setCurrentDocumentIndex, sxContainer } = props;

  const targetRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Check if the element is intersecting the middle of the viewport
        if (entry.isIntersecting && entry.boundingClientRect.top < window.innerHeight / 2) {
          setCurrentDocumentIndex(documentIndex);
        }
      },
      {
        root: null, // observing viewport
        threshold: 0, // trigger as soon as any part of the element is visible
        rootMargin: '-50% 0px -50% 0px' // top/bottom margin to trigger at middle of viewport
      }
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, []);

  return (
    <Box ref={targetRef} sx={sxContainer} data-cy="board-member-view_document-view-button-container">
      <Button
        sx={{
          textTransform: 'none',
          color: 'secondary.light',
          p: 0.2,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '100%'
        }}
        component="a"
        target="_blank"
        rel="noopener"
        data-cy="board-member-view_document-view-button"
        href={`/documents/${document.id}`}
      >
        <RenderDocumentIcon fileType={document.attachedFile.format} sx={{ opacity: 1 }} />

        <Box
          sx={{
            ml: 1,
            maxWidth: '500px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: '16px'
          }}
        >
          {document?.attachedFile?.name || document?.attachedFile?.uri}
        </Box>
      </Button>
    </Box>
  );
};
