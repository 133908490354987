import { Stack, Box } from '@mui/material';
import { BoardMemberViewSidebar } from './BoardMemberViewSidebar';
import { BoardMemberViewHeader } from './BoardMemberViewHeader';
import { BoardMemberViewBody } from './BoardMemberViewBody';
import { BoardMemberViewMobileSidebar } from './BoardMemberViewSidebar/BoardMemberViewMobileSidebar';

interface IBoardMemberViewProps {}

export const BoardMemberView: React.FC<IBoardMemberViewProps> = (props) => {
  return (
    <>
      <Stack direction="column" sx={{ height: '100vh', backgroundColor: '#FAFAFA' }} className="rr-block">
        <BoardMemberViewHeader />
        <Stack direction="row">
          <BoardMemberViewSidebar />
          <BoardMemberViewMobileSidebar />

          <Box
            sx={{
              height: '100%',
              minWidth: 0,
              flexGrow: 1
            }}
          >
            <BoardMemberViewBody />
            <Box style={{ height: '100vh', backgroundColor: '#FAFAFA' }} />
          </Box>
        </Stack>
      </Stack>
    </>
  );
};
