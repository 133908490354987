import { IMeeting } from 'types';
import { useParamsKey, useResourceShow } from '@koopajs/react';
import { MeetingLoadingOrError } from 'components/Meetings/MeetingLoadingOrError';
import { BoardMemberView } from 'components/BoardMemberView';
import { BoardMemberViewProvider } from 'components/BoardMemberView/BoardMemberViewProvider';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useScreenSize } from 'utils/useScreenSize';

export const MeetingBoardMemberViewPage: React.FC = () => {
  const meetingId = useParamsKey('meetingId');
  const {
    resource: meeting,
    isProcessing,
    errorMessage
  } = useResourceShow<IMeeting>({
    path: '/meetings',
    id: meetingId
  });

  const { isScreenAboveLg, isMobileScreen } = useScreenSize();

  const location = useLocation();
  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      // Delay the scroll to ensure DOM is ready
      setTimeout(() => {
        //desktop we scroll to the document
        const documentId = hash.slice(1);
        const documentElement = document.getElementById(documentId);

        if (documentElement && isScreenAboveLg) {
          documentElement.scrollIntoView({ behavior: 'smooth' });
        }

        //mobile we scroll to the topic
        if (!isScreenAboveLg) {
          const topicId = documentElement?.dataset.topicId;
          const offset = isMobileScreen ? 55 : 63; // header height + 1px (so it's highlighted)
          const topicElement = document.getElementById(`topic-card-${topicId}`);
          const topicPosition = topicElement ? topicElement.getBoundingClientRect().top : 0;

          window.scrollTo({
            top: topicPosition - offset,
            behavior: 'smooth'
          });
        }
      }, 2000);
    }
  }, [location.hash]);

  // const { user: currentUser } = useUserShow();

  // TODO: do we care about this?
  // const { hasMeetingStarted, hasMeetingEnded, isUserCurrentNoteTaker } = useMeetingStatus({
  //   meeting,
  //   user: currentUser
  // });

  // const [searchParams] = useQueryParams({ redirecting: StringParam });

  // const doesUserHaveAccessToPage = hasMeetingStarted && !hasMeetingEnded && isUserCurrentNoteTaker;

  if (!meeting) {
    return <MeetingLoadingOrError isProcessing={isProcessing} errorMessage={errorMessage} />;
  }

  return (
    <BoardMemberViewProvider meeting={meeting}>
      <BoardMemberView />
    </BoardMemberViewProvider>
  );
};
