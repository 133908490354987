import { Box } from '@mui/material';
import RichTextReadOnly from 'components/temp/RichTextMultiLine/RichTextReadOnly';

interface ITopicDescriptionProps {
  description: string;
}

export const TopicDescription: React.FC<ITopicDescriptionProps> = (props) => {
  const { description } = props;
  return (
    <Box
      sx={{
        width: { lg: 'calc(8px + 912px + (( 100% - 928px ) / 2))' }, // 8 + 8 + 912 = 928
        mb: 3
      }}
    >
      <RichTextReadOnly value={description} />
    </Box>
  );
};
