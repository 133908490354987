import { useLocale } from '@koopajs/react';
import { Chip, SxProps, Theme } from '@mui/material';

export const handleChipColor = (topicType?: string): 'info' | 'success' | 'warning' | undefined => {
  if (topicType === 'information') {
    return 'info';
  }
  if (topicType === 'discussion') {
    return 'warning';
  }
  if (topicType === 'resolution') {
    return 'success';
  }
  return undefined;
};

interface ITopicChip {
  topicType: string;
  sx?: SxProps<Theme>;
  variant?: 'filled' | 'outlined';
  color?: 'info' | 'success' | 'warning' | 'default' | 'primary' | 'secondary' | 'error';
}

export const TopicChip: React.FC<ITopicChip> = (props) => {
  const { topicType, sx, variant, color } = props;
  const { t } = useLocale();
  // accept information, resolution, discussion
  if (!topicType) return null;

  return (
    <Chip
      label={t(`common:topicTypes.${topicType}`)}
      color={color || handleChipColor(topicType)}
      sx={sx}
      variant={variant}
    />
  );
};
