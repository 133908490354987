import { useLocale, useParamsKey, useResourceList } from '@koopajs/react';
import { ICommittee } from 'types';
import { Typography, Box, Stack, Button } from '@mui/material';
import { CreateButton } from 'components/CreateButton';
import { Helmet } from 'react-helmet';
import { PageContainer } from 'components/temp/PageContainer';
import { Link as RouterLink } from 'react-router-dom';
import { MembersList } from 'components/Organization/OrganizationMembers/MembersList';
import { IUserPublicProfile } from '@koopajs/app';
import { OrganizationCommittees } from 'components/Organization/OrganizationCommittees';

export const Organization: React.FC = () => {
  const urlParam = useParamsKey('key') || '';
  const usersUseResourceList = useResourceList<IUserPublicProfile>({
    path: '/organization-users',
    searchParams: { size: 50 }
  });

  const committeesUseResourceList = useResourceList<ICommittee>({
    path: '/committees',
    searchParams: { size: 50, sort: 'name.keyword:ASC' }
  });

  const { t } = useLocale();
  const keyPrefix = 'Organization';

  const renderPartOfNoGroup = (): JSX.Element | undefined => {
    return (
      <Stack sx={{ marginTop: '24px' }}>
        <Stack
          direction="row"
          spacing={2}
          sx={{
            backgroundColor: '#FFFFFF',
            padding: '24px',
            borderRadius: '4px',
            border: '1px solid #dddddd'
          }}
        >
          <Typography>{t(keyPrefix + '.noGroupText')}</Typography>
        </Stack>
      </Stack>
    );
  };
  return (
    <>
      <Helmet>
        <title>{`${t('common:navigation.organization')} - Panorama`}</title>
      </Helmet>
      <PageContainer
        sxChildren={{ px: { xs: '24px', lg: '48px' }, py: '24px', position: 'relative' }}
        className="rr-block"
      >
        <Typography variant="h1" sx={{ fontSize: '24px' }}>
          {t(keyPrefix + '.title')}
        </Typography>

        {/* groups */}
        {urlParam === '' && <OrganizationCommittees committeesUseResourceList={committeesUseResourceList} />}

        {/* members */}
        {urlParam === 'members' && <MembersList usersUseResourceList={usersUseResourceList} />}

        {/* no group */}
        {urlParam === '' &&
          committeesUseResourceList.state === 'loaded-no-search-result' &&
          renderPartOfNoGroup()}

        {/* no members */}
        {urlParam === 'members' &&
          usersUseResourceList.state === 'loaded-no-search-result' &&
          renderPartOfNoGroup()}
      </PageContainer>
    </>
  );
};
