import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {
  Button,
  Box,
  Drawer,
  Toolbar,
  List,
  Typography,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import CircleIcon from '@mui/icons-material/Circle';
import { DisabledDocumentList } from './PastMeetingApproval/DisabledDocumentList';
import { MinutesToApproveItem } from './PastMeetingApproval/MinutesToApproveItem';
import { useBoardMemberViewContext } from '../BoardMemberViewProvider';
import { useLocale } from '@koopajs/react';
import { checkIsDecisionTopicType } from 'utils/topicTypeArrays';
import { handleChipColor } from 'components/TopicChip';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import { useScreenSize } from 'utils/useScreenSize';

const drawerWidth: string = '100%';

interface IAppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})<IAppBarProps>(({ theme }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen
        })
      }
    }
  ]
}));

export const BoardMemberViewMobileSidebar: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const boardMemberViewContext = useBoardMemberViewContext();
  const { meeting, currentTopicId } = boardMemberViewContext;
  const { t } = useLocale();

  const toggleDrawer = (): void => {
    setIsDrawerOpen((prevState) => !prevState);
  };

  const { isMobileScreen } = useScreenSize();

  const keyPrefix = 'BoardMemberView.Sidebar';

  const handleOnClick = (topicId: string): void => {
    const element = document.getElementById(`topic-card-${topicId}`);
    if (element) {
      const yOffset = 1; // additional off set for the topic accordion to be highlighted
      const stickyToolbarHeight = isMobileScreen ? 56 : 64;
      const topicPosition =
        element.getBoundingClientRect().top + window.scrollY + yOffset - stickyToolbarHeight;

      window.scrollTo({ top: topicPosition, behavior: 'smooth' });

      toggleDrawer();
    }
  };

  return (
    <Box sx={{ display: { xs: 'flex', lg: 'none' } }} data-cy="board-member-view_agenda-mobile">
      <AppBar
        elevation={0}
        position="fixed"
        sx={{
          backgroundColor: 'white',
          zIndex: 1300
        }}
      >
        <Toolbar
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
          id="toolbar"
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1" color="text.primary" data-cy="board-member-view-mobile_sidebar-title">
              {t('common:agenda')}
            </Typography>
            <IconButton
              color="inherit"
              aria-label={isDrawerOpen ? t(keyPrefix + '.openDrawer') : t(keyPrefix + '.closeDrawer')}
              onClick={toggleDrawer}
              edge="end"
              sx={[
                {
                  ml: 1,
                  backgroundColor: '#EDF0FF',
                  width: '24px',
                  height: '24px'
                }
              ]}
              data-cy="board-member-view_mobile-sidebar-toggle"
            >
              {isDrawerOpen ? (
                <KeyboardArrowUp sx={{ color: '#151E54' }} />
              ) : (
                <KeyboardArrowDown sx={{ color: '#151E54' }} />
              )}
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            top: { xs: '56px', sm: '64px' },
            height: { xs: 'calc(100vh - 56px)', sm: 'calc(100vh - 64px)' },
            p: '14px'
          }
        }}
        variant="persistent"
        anchor="top"
        open={isDrawerOpen}
        data-cy="board-member-view_mobile-sidebar-drawer"
      >
        <List sx={{ p: 0, m: 1, gap: 1 }}>
          {/* 
            TOPICS 
          */}
          {meeting?.topics?.map((topic, index) => {
            const isTopicResolution = checkIsDecisionTopicType(topic.type);
            const topicType = isTopicResolution ? 'resolution' : topic.type;
            const chipColor = handleChipColor(topicType);

            return (
              <ListItem
                key={topic.id}
                disablePadding
                sx={{
                  p: 0,
                  mx: 0,
                  my: '14px'
                }}
              >
                <ListItemButton
                  sx={{
                    p: '8px 14px',
                    m: 0,
                    backgroundColor: currentTopicId === topic.id ? '#EDF0FF' : 'inherit',
                    borderRadius: '20px'
                  }}
                  component="a"
                  onClick={() => handleOnClick(topic.id)}
                  data-cy="board-member-view_mobile-sidebar-topic"
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1,
                      width: '100%'
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
                      <Typography variant="body1" sx={{ whiteSpace: 'normal' }}>
                        {`${index + 1}. ${topic.title ? topic.title : t(`common:topicTypes.${topic.type}`)}`}
                      </Typography>
                      {chipColor && <CircleIcon color={chipColor} sx={{ fontSize: '14px' }} />}
                    </Box>

                    <DisabledDocumentList
                      path={`/meetings/${topic.meetingId}/topics/${topic.id}/documents`}
                    />

                    {topic.type === 'pastMinutesApproval' &&
                      topic.pastMeetingsToApprove &&
                      topic.pastMeetingsToApprove.length > 0 && (
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start'
                          }}
                        >
                          {topic?.pastMeetingsToApprove?.map(({ meetingId }) => (
                            <MinutesToApproveItem key={meetingId} minutesId={meetingId} />
                          ))}
                        </Box>
                      )}
                  </Box>
                </ListItemButton>
              </ListItem>
            );
          })}

          <ListItemButton
            component={Link}
            to="/"
            sx={{
              backgroundColor: '#EEE',
              padding: '4px 14px',
              borderRadius: '100px',
              height: '48px'
            }}
            aria-label={t(keyPrefix + '.labelExit')}
          >
            <ListItemIcon sx={{ minWidth: 0 }}>
              <CloseIcon />
            </ListItemIcon>

            <ListItemText sx={{ ml: 1 }}>{t(keyPrefix + '.labelExit')}</ListItemText>
          </ListItemButton>
        </List>
      </Drawer>
    </Box>
  );
};
