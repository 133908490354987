import { IDocumentRecord } from 'types';
import { useCallback, useState } from 'react';
import { useComponentVisibility, useLocale } from '@koopajs/react';
import { Typography, Box, Stack, Button, Chip } from '@mui/material';
import { Layout, Loading } from '@koopajs/mui';
import { Sidebar } from 'components/Sidebars/Sidebar';
import { FilterChips } from '../components/FilterChips';
import { MobileFloatingButtons } from 'components/MobileFloatingButtons';
import { Helmet } from 'react-helmet';
import { useResourceList } from 'components/temp/useResourceListTemp';
import { CommitteeFolderList } from 'components/DocumentRecords/CommitteeFolderList';
import { DocumentsTable } from 'components/DocumentRecords/DocumentsTable';
import { AddDocumentRecordButton } from 'components/DocumentRecords/AddDocumentRecordButton';
import { useResourceListQuery } from 'utils/useResourceListQuery';
import { useUploadDocumentRecordPermissions } from 'utils/DocumentRecords/useUploadDocumentRecordPermissions';
import { CategoryFolderList } from 'components/DocumentRecords/CategoryFolderList';
import { PageContainer } from 'components/temp/PageContainer';
import { useFeatureGate } from '@statsig/react-bindings';
import { FeatureLocked } from 'components/FeatureLocked';

export const DocumentRecords: React.FC = () => {
  const { t } = useLocale();
  const keyPrefix = 'DocumentRecords';

  const { search, handlePageChange, handleSizeChange } = useResourceListQuery();

  const { canUserUploadDocuments, committeesUserCanUpload } = useUploadDocumentRecordPermissions();

  const sidebarVisibility = useComponentVisibility('sidebar');

  const {
    resources: documentRecords,
    state,
    isProcessing,
    totalCount
  } = useResourceList<IDocumentRecord>({
    path: '/document-records',
    searchParams: { sort: 'lastDocumentActiveSince:DESC', size: 10, ...search.searchParams }
  });

  const { value: isDocumentRecordsEnabled } = useFeatureGate('document-records-preview');

  if (!isDocumentRecordsEnabled) {
    return <FeatureLocked />;
  }

  if (state === 'loading')
    return (
      <Stack sx={{ position: 'relative', width: '100%', height: '100%' }}>
        <Loading sx={{ backgroundColor: 'transparent', position: 'absolute' }} />
      </Stack>
    );

  return (
    <>
      <Helmet>
        <title>{`${t('common:navigation.documents')} - Panorama`}</title>
      </Helmet>
      <PageContainer
        sxChildren={{ px: { xs: '24px', lg: '48px' }, py: '24px' }}
        // sidebar={
        //   <Stack spacing={3} sx={{ px: '12px', py: '24px' }}>
        //     <Sidebar facetList={<FacetListDocuments />} onHideSidebar={sidebarVisibility.setHidden} />
        //   </Stack>
        // }
      >
        {/* <MobileFloatingButtons
          fabData={[
            {
              label: t('common:filters'),
              onClick: sidebarVisibility.setVisible
            }
          ]}
        /> */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '24px'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              flexDirection: { xs: 'column', sm: 'row' },
              gap: { xs: 2, sm: 0 }
            }}
            data-cy="document-records_header"
          >
            <Typography
              variant="h1"
              sx={{
                fontSize: '24px'
              }}
            >
              {t(keyPrefix + '.title')}
            </Typography>{' '}
            {canUserUploadDocuments ? (
              <AddDocumentRecordButton committees={committeesUserCanUpload} />
            ) : (
              // hidden button to reduce layout shift while we wait for permissions request
              <Button sx={{ visibility: 'hidden' }}>.</Button>
            )}
          </Box>
          <CommitteeFolderList />
          <CategoryFolderList />

          <FilterChips isProcessing={isProcessing} />
          <DocumentsTable
            documentRecords={documentRecords}
            totalCount={totalCount}
            search={search}
            listState={state}
            onPageChange={handlePageChange}
            onSizeChange={handleSizeChange}
            canUserUploadDocuments={canUserUploadDocuments}
            committeesUserCanUploadTo={committeesUserCanUpload}
          />
          {/* document total count */}
          <Box
            sx={{ borderRadius: '4px', border: '1px solid rgba(0, 0, 0, 0.12)', p: '14px', display: 'flex' }}
          >
            <Typography variant="caption" color="text.secondary">
              {totalCount || 0} {t(keyPrefix + '.files')}
            </Typography>
          </Box>
        </Box>
      </PageContainer>
    </>
  );
};
