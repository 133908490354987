import { useResourceList, useLocale } from '@koopajs/react';
import { Stack, Typography, Box } from '@mui/material';
import { IDocument } from 'types';
import { FilePresent as FilePresentIcon, OpenInNew as OpenInNewIcon } from '@mui/icons-material';

interface IDisabledDocumentListBaseProps {
  path: string;
}

export const DisabledDocumentList: React.FC<IDisabledDocumentListBaseProps> = (props) => {
  const { path } = props;

  const { resources: documents } = useResourceList<IDocument>({
    path: path
  });

  return (
    <>
      {documents.length > 0 && (
        <>
          <Stack spacing={1}>
            {documents.map((document, index) => {
              return (
                <Stack key={document.id} direction="row">
                  <Box sx={{ maxWidth: '100%', opacity: 0.6 }} data-cy="">
                    <Box
                      sx={{
                        display: 'flex',
                        p: 0.2,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '100%'
                      }}
                    >
                      {document?.attachedFile?.format === 'url' ? <OpenInNewIcon /> : <FilePresentIcon />}

                      <Box
                        sx={{
                          ml: 1,
                          maxWidth: '500px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}
                      >
                        <Typography variant="body1">
                          {document?.attachedFile?.name || document?.attachedFile?.uri}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Stack>
              );
            })}
          </Stack>
        </>
      )}
    </>
  );
};
