import { Stack } from '@mui/material';
import { useBoardMemberViewContext } from '../BoardMemberViewProvider';
import { calculateTopicsStartTime } from 'utils/calculateTopicsStartTime';
import { BoardMemberViewQuorumCard } from './TopicCard/QuorumCard';
import { BoardMemberViewAgendaApprovalCard } from './TopicCard/AgendaApprovalCard';
import { BoardMemberViewPastMinutesApprovalCard } from './TopicCard/PastMinutesApprovalCard';
import { BoardMemberViewBasicTopicCard } from './TopicCard/BasicTopic';
import { BoardMemberViewInCameraCard } from './TopicCard/InCameraCard';

interface IBoardMemberViewBodyProps {}

export const BoardMemberViewBody: React.FC<IBoardMemberViewBodyProps> = (props) => {
  const boardMemberViewContext = useBoardMemberViewContext();

  const { meeting } = boardMemberViewContext;

  const topics = meeting && calculateTopicsStartTime(meeting);

  return (
    <Stack
      sx={{
        whiteSpace: 'pre',
        padding: { xs: 2, lg: '24px 24px 24px 4px' },
        backgroundColor: '#FAFAFA'
      }}
      gap="14px"
    >
      {topics?.map((topic, index) => {
        switch (topic.type) {
          case 'quorum':
            return <BoardMemberViewQuorumCard topic={topic} index={index} key={topic.id} />;
          case 'nominations':
            return <BoardMemberViewBasicTopicCard topic={topic} index={index} key={topic.id} />;
          case 'agendaApproval':
            return <BoardMemberViewAgendaApprovalCard topic={topic} index={index} key={topic.id} />;
          case 'pastMinutesApproval':
            return <BoardMemberViewPastMinutesApprovalCard topic={topic} index={index} key={topic.id} />;
          case 'ceoReport':
            return <BoardMemberViewBasicTopicCard topic={topic} index={index} key={topic.id} />;
          case 'information':
            return <BoardMemberViewBasicTopicCard topic={topic} index={index} key={topic.id} />;
          case 'discussion':
            return <BoardMemberViewBasicTopicCard topic={topic} index={index} key={topic.id} />;
          case 'resolution':
            return <BoardMemberViewBasicTopicCard topic={topic} index={index} key={topic.id} />;
          case 'miscellaneous':
            return <BoardMemberViewBasicTopicCard topic={topic} index={index} key={topic.id} />;
          case 'inCamera':
            return <BoardMemberViewInCameraCard topic={topic} index={index} key={topic.id} />;
          case 'adjournment':
            return <BoardMemberViewBasicTopicCard topic={topic} index={index} key={topic.id} />;
          default:
            return null;
        }
      })}
    </Stack>
  );
};
