import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ITopic } from 'types';
import { useEffect, useRef, useState } from 'react';
import { useBoardMemberViewContext } from 'components/BoardMemberView/BoardMemberViewProvider';
import { useScreenSize } from 'utils/useScreenSize';

interface IBoardMemberViewTopicCardAccordion {
  topic: ITopic;
  accordionSummary: React.ReactNode;
  accordionDetails: React.ReactNode;
  index: number;
  isDisabled?: boolean;
}

export const BoardMemberViewTopicCardAccordion: React.FC<IBoardMemberViewTopicCardAccordion> = (props) => {
  const { topic, accordionSummary, accordionDetails, index, isDisabled } = props;
  const { isMobileScreen, isScreenAboveLg } = useScreenSize();

  const boardMemberViewContext = useBoardMemberViewContext();

  const isAccordionCollapsed = isDisabled || !isScreenAboveLg;

  const [isExpanded, setExpanded] = useState(isAccordionCollapsed ? false : true);

  const { setCurrentTopicId, currentTopicId } = boardMemberViewContext;

  const handleChange = () => {
    setExpanded(!isExpanded);
  };

  const componentRef = useRef<HTMLDivElement>(null);

  let triggerPoint = 64; // Height of the sticky toolbar on tablet - sm to lg

  if (isMobileScreen) {
    triggerPoint = 56;
  } else if (isScreenAboveLg) {
    triggerPoint = 0;
  }

  useEffect(() => {
    const handleScroll = (): void => {
      if (componentRef.current) {
        const componentContainer = componentRef.current.getBoundingClientRect();

        if (componentContainer.top <= triggerPoint && componentContainer.bottom > triggerPoint) {
          setCurrentTopicId(topic.id);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [topic.id, setCurrentTopicId]);

  const collapsedStyles = {
    borderBottom: '1px solid #BDBDBD',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px'
  };

  return (
    <Accordion
      elevation={0}
      disabled={Boolean(isDisabled)}
      sx={{
        margin: '0px !important',
        '&:before': {
          display: 'none'
        },
        '&.Mui-disabled': {
          backgroundColor: 'transparent'
        }
        // border: '1px solid #BDBDBD',
        // borderRadius: '4px'
        // overflow: 'hidden',
      }}
      expanded={isExpanded}
      onChange={handleChange}
      defaultExpanded
      id={`topic-card-${topic.id}`}
      data-cy={`topic-card-accordion`}
      ref={componentRef}
    >
      <AccordionSummary
        expandIcon={isDisabled ? null : <ExpandMoreIcon />}
        aria-controls={`${topic.id}-content`}
        id={`${topic.id}-header`}
        sx={{
          backgroundColor: currentTopicId === topic.id ? '#EDF0FF' : '#F5F5F5',
          position: 'sticky',
          top: { xs: '56px', sm: '64px', lg: 0 }, //xs fixed agenda toolbar height
          minHeight: '0px!important',
          padding: '0 24px',
          borderTop: '1px solid #BDBDBD',
          borderLeft: '1px solid #BDBDBD',
          borderRight: '1px solid #BDBDBD',

          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
          '& .MuiAccordionSummary-content': { my: '8px!important' },
          zIndex: 1,
          ...(isDisabled
            ? {
                '&.Mui-disabled': {
                  opacity: 1
                }
              }
            : {}),
          ...(!isExpanded ? collapsedStyles : {})
        }}
      >
        {accordionSummary}
      </AccordionSummary>

      {!isDisabled && (
        <AccordionDetails
          data-cy="topic-card_accordion-body"
          sx={{
            p: { xs: 2, sm: 3 },
            backgroundColor: '#FFFFFF',
            borderBottomLeftRadius: '4px',
            borderBottomRightRadius: '4px',
            borderLeft: '1px solid #BDBDBD',
            borderRight: '1px solid #BDBDBD',
            borderBottom: '1px solid #BDBDBD'
          }}
        >
          {accordionDetails}
        </AccordionDetails>
      )}
    </Accordion>
  );
};
