import {
  Box,
  IconButton,
  Avatar,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack
} from '@mui/material';
import { useBoardMemberViewContext } from '../BoardMemberViewProvider';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useLocale } from '@koopajs/react';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CircleIcon from '@mui/icons-material/Circle';
import { MinutesToApproveItem } from './PastMeetingApproval/MinutesToApproveItem';
import { handleChipColor } from 'components/TopicChip';
import { checkIsDecisionTopicType } from 'utils/topicTypeArrays';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import { DisabledDocumentList } from './PastMeetingApproval/DisabledDocumentList';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { BoardMemberViewHeader } from '../BoardMemberViewHeader';

interface IBoardMemberViewSidebarProps {}

const drawerWidth = 400;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(10)} + 1px)`
  }
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  variants: [
    {
      props: ({ open }) => open,
      style: {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme)
      }
    },
    {
      props: ({ open }) => !open,
      style: {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme)
      }
    }
  ]
}));

export const BoardMemberViewSidebar: React.FC<IBoardMemberViewSidebarProps> = (props) => {
  const boardMemberViewContext = useBoardMemberViewContext();

  const { meeting, currentTopicId, setCurrentTopicId, isDrawerOpen, setIsDrawerOpen } =
    boardMemberViewContext;
  const [isScrolled, setIsScrolled] = useState(false);

  const { t } = useLocale();
  const keyPrefix = 'BoardMemberView.Sidebar';

  const handleOnClick = (topicId: string): void => {
    const element = document.getElementById(`topic-card-${topicId}`);
    if (element) {
      const yOffset = 1; // additional off set for the topic accordion to be highlighted

      const topicPosition = element.getBoundingClientRect().top + window.scrollY + yOffset;

      window.scrollTo({ top: topicPosition, behavior: 'smooth' });
    }
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  useEffect(() => {
    const handleScroll = (): void => {
      // Check if scrollY is greater than 96px - height of the header 72px + 24px padding of the body

      if (window.scrollY > 96) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Box sx={{ display: { xs: 'none', lg: 'flex' } }} id="sidebar" component="nav">
      <Drawer
        id="drawer"
        variant="permanent"
        open={isDrawerOpen}
        sx={{
          backgroundColor: '#FAFAFA',
          '& .MuiDrawer-paper': {
            borderRight: 'none',
            left: 'auto',
            height: isScrolled ? '100vh' : 'calc(100vh - 72px)',
            backgroundColor: '#FAFAFA',
            top: isScrolled ? '0' : 'auto'
          }
        }}
      >
        {isDrawerOpen && isScrolled && <BoardMemberViewHeader sxContainer={{ m: 1, p: 1 }} />}
        <List sx={{ p: 0, m: 1, gap: 1, mt: isScrolled ? 1 : 3 }}>
          {/*
            OPEN / CLOSE
          */}
          <ListItem sx={{ m: 0, p: 0 }}>
            {isDrawerOpen ? (
              <Stack direction="row" sx={{ justifyContent: 'space-between', width: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Avatar sx={{ bgcolor: '#EDF0FF', p: 0, width: 48, height: 48 }}>
                    <ListAltIcon sx={{ color: '#151E54' }} />
                  </Avatar>
                  <Typography variant="body1">{t('common:agenda')}</Typography>
                </Box>
                <IconButton
                  onClick={handleDrawerClose}
                  sx={{
                    width: 48,
                    height: 48
                  }}
                  aria-label={t(keyPrefix + '.closeDrawer')}
                >
                  <KeyboardArrowLeftIcon />
                </IconButton>
              </Stack>
            ) : (
              <IconButton
                color="inherit"
                onClick={handleDrawerOpen}
                edge="start"
                sx={[
                  {
                    backgroundColor: '#EDF0FF',
                    p: 0,
                    width: 48,
                    height: 48,
                    m: 0
                  },
                  isDrawerOpen && { display: 'none' }
                ]}
                aria-label={t(keyPrefix + '.openDrawer')}
              >
                <KeyboardArrowRightIcon sx={{ color: '#151E54' }} />
              </IconButton>
            )}
          </ListItem>

          {/* 
            TOPICS 
          */}
          {meeting?.topics?.map((topic, index) => {
            const isTopicResolution = checkIsDecisionTopicType(topic.type);
            const topicType = isTopicResolution ? 'resolution' : topic.type;
            const chipColor = handleChipColor(topicType);

            return (
              <ListItem
                key={topic.id}
                disablePadding
                sx={{
                  p: 0,
                  mx: 0,
                  my: '14px'
                }}
              >
                {isDrawerOpen ? (
                  <ListItemButton
                    sx={{
                      p: '8px 14px',
                      m: 0,
                      backgroundColor: currentTopicId === topic.id ? '#EDF0FF' : 'inherit',
                      borderRadius: '20px'
                    }}
                    component="a"
                    onClick={() => handleOnClick(topic.id)}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        width: '100%'
                      }}
                    >
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
                        <Typography variant="body1" sx={{ whiteSpace: 'normal' }}>
                          {`${index + 1}. ${
                            topic.title ? topic.title : t(`common:topicTypes.${topic.type}`)
                          }`}
                        </Typography>
                        {chipColor && <CircleIcon color={chipColor} sx={{ fontSize: '14px' }} />}
                      </Box>

                      <DisabledDocumentList
                        path={`/meetings/${topic.meetingId}/topics/${topic.id}/documents`}
                      />

                      {topic.type === 'pastMinutesApproval' &&
                        topic.pastMeetingsToApprove &&
                        topic.pastMeetingsToApprove.length > 0 && (
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-start'
                            }}
                          >
                            {topic?.pastMeetingsToApprove?.map(({ meetingId }) => (
                              <MinutesToApproveItem key={meetingId} minutesId={meetingId} />
                            ))}
                          </Box>
                        )}
                    </Box>
                  </ListItemButton>
                ) : (
                  <ListItemButton
                    sx={{
                      textAlign: 'center',
                      p: '8px 14px',
                      m: 0,
                      backgroundColor: currentTopicId === topic.id ? '#EDF0FF' : 'inherit',
                      borderRadius: '20px',
                      border: '1px solid #bdbdbd',
                      maxWidth: '48px'
                    }}
                    component="a"
                    onClick={() => handleOnClick(topic.id)}
                  >
                    <ListItemText sx={{ m: 0, p: 0 }}>{`${index + 1}.`}</ListItemText>
                  </ListItemButton>
                )}
              </ListItem>
            );
          })}

          {/* 
            EXIT
          */}
          <ListItem sx={{ m: 0, p: 0 }}>
            {isDrawerOpen ? (
              <ListItemButton
                component={Link}
                to="/"
                sx={{
                  backgroundColor: '#EEE',
                  padding: '4px 14px',
                  borderRadius: '100px',
                  height: '48px'
                }}
                aria-label={t(keyPrefix + '.labelExit')}
              >
                <ListItemIcon sx={{ minWidth: 0 }}>
                  <CloseIcon />
                </ListItemIcon>

                <ListItemText sx={{ ml: 1 }}>{t(keyPrefix + '.labelExit')}</ListItemText>
              </ListItemButton>
            ) : (
              <ListItemButton
                component={Link}
                to="/"
                sx={{
                  backgroundColor: '#00000014',
                  borderRadius: '50%',
                  justifyContent: 'center',
                  p: 0,
                  maxWidth: 48,
                  height: 48
                }}
                aria-label={t(keyPrefix + '.labelExit')}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 'unset'
                  }}
                >
                  <CloseIcon />
                </ListItemIcon>
              </ListItemButton>
            )}
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
};
