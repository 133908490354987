import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { useFeatureGate } from '@statsig/react-bindings';
import { IDocument } from 'types';
import { DocumentIframeS3 } from 'components/DocumentIframeS3';
import { useWorkspaceShow } from '@koopajs/react';
import { useLocale } from '@koopajs/react';
const DOCUMENT_ANNOTATION_APP_URL: string = process.env.REACT_APP_DOCUMENT_ANNOTATION_APP_URL!;

interface IBoardMemberViewTopicCardDocumentAnnotation {
  document: IDocument;
  documentIndex: number;
  setCurrentDocumentIndex: Dispatch<SetStateAction<number>>;
}

export const BoardMemberViewTopicCardDocumentAnnotation: React.FC<
  IBoardMemberViewTopicCardDocumentAnnotation
> = (props) => {
  const { document, documentIndex, setCurrentDocumentIndex } = props;

  const { locale } = useLocale();

  const { workspace } = useWorkspaceShow();

  const targetRef = useRef(null);

  const { value: isDocumentAnnotationsEnabled } = useFeatureGate('document_annotations');

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Check if the element is intersecting the middle of the viewport
        if (entry.isIntersecting && entry.boundingClientRect.top < window.innerHeight / 2) {
          setCurrentDocumentIndex(documentIndex);
        }
      },
      {
        root: null, // observing viewport
        threshold: 0, // trigger as soon as any part of the element is visible
        rootMargin: '-50% 0px -50% 0px' // top/bottom margin to trigger at middle of viewport
      }
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, []);

  const sx = {
    width: '100%',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
    height: { xs: 'calc(100vh - 64px)', lg: '100vh' }
  };

  return (
    <Box ref={targetRef} id={document.id} data-topic-id={document.topicId}>
      {isDocumentAnnotationsEnabled ? (
        <Box
          data-cy="document-annotation-iframe"
          component="iframe"
          sx={sx}
          src={`${DOCUMENT_ANNOTATION_APP_URL}/${workspace?.id}/d/${document.id}?locale=${locale}`}
        />
      ) : (
        <DocumentIframeS3 id={document.id} sxIframe={sx} sxLoadingContainer={sx} />
      )}
    </Box>
  );
};
